const PAYMENT_METHODS = ['cash', 'card', 'bill', 'account']

import { PaymentMethod } from './PaymentMethod'

export class Payment {

  constructor(totalAmount) {
    this.paymentMethods = [new PaymentMethod('cash', totalAmount)]
    this.totalAmount = totalAmount
    this.secondMethodActive = false
  }

  toggleSecondMethod(){
    if(!this.secondMethodActive){
      const usedType = this.paymentMethods[0].type
      const type = PAYMENT_METHODS.find(a => a != usedType)
      const amount = this.totalAmount / 2 
      this.paymentMethods.push(new PaymentMethod(type, amount))
      this.paymentMethods[0].changeAmount(amount)
    }
    else {
      this.paymentMethods.pop()
      this.paymentMethods[0].changeAmount(this.totalAmount)
    }
    this.secondMethodActive = !this.secondMethodActive
  }

  changePaymentMethodAmount(index, newAmount){
    if(newAmount >= this.totalAmount || !this.secondMethodActive || newAmount < 1) {
      this.paymentMethods[index].amount += 1 //NOTE: This is because vue reactiveness must be advised to reset input value
      this.paymentMethods[index].amount -= 1
      return
    }
    this.paymentMethods[index].changeAmount(newAmount)
    if(this.secondMethodActive){
      const otherMethodAmount = this.totalAmount - newAmount
      const otherMethodIndex = index == 0 ? 1 : 0
      this.paymentMethods[otherMethodIndex].changeAmount(otherMethodAmount)
    }
  }

  changePaymentMethod(index, method){
    const oldMethod = this.paymentMethods[index]
    const newPaymentMethod = new PaymentMethod(method, oldMethod.amount)
    this.paymentMethods.splice(index, 1, newPaymentMethod)
  }

  changeSelectedCard(name, fee){
    const cardMethod = this.paymentMethods.find(el => el.type == 'card')
    cardMethod.changeCardInfo(name, fee)
  }

  cardFinancedAmount(cte){
    const cardMethod = this.paymentMethods.find(e => e.type == 'card')
    return cardMethod?.financedAmount(cte) || 0
  }

  financedTotalAmount(cte){
    return this.totalAmount - this.cardRawAmount + +this.cardFinancedAmount(cte)
  }

  formatPaymentForSubmit(cte){
    const paymentArray = []
    for(let payment of this.paymentMethods){
      const method = {
        methodName: payment.type,
        amount: payment.type == 'card' ? +this.cardFinancedAmount(cte) : payment.amount
      }
      if(payment.type == 'card') method.cardInfo = payment.cardInfo
      paymentArray.push(method)
    }
    return paymentArray
  }

  get cardRawAmount(){
    const cardMethod = this.paymentMethods.find(e => e.type == 'card')
    return cardMethod ? cardMethod.amount : 0
  }

  get availablePaymentMethods(){
    const availableMethods = []
    for(let paymentMethod of PAYMENT_METHODS)
      if(!this.paymentMethods.some(el => el.type == paymentMethod)) availableMethods.push(paymentMethod)
    return availableMethods
  }

  get hasCardPaymentMethod(){
    return this.paymentMethods.some(el => el.type == 'card')
  }

  get hasSelectedCard(){
    const cardMethod = this.paymentMethods.find(el => el.type == 'card')
    return cardMethod.hasSelectedCard
  }

}
