import { getProductIndex } from '../utils.js'

export class Cart{

    constructor(){
        this.products = [];
        this.totalQty = 0 ;
        this.totalAmount = 0;
    }
    
    setTotalAmount = (amount) => {
        this.totalAmount = amount
    }

    addProduct = (product, type) => {
        if(getProductIndex(this.products, product._id) != -1)
            return 'Producto ya agregado'
        product.subTotal = +product.price
        product.qty = 1
        product.type = type
        if(product.serial)
            product.serial = product.serial[0]
        this.products.push(product)
        this.totalQty++
        this.totalAmount += product.price
        return null
    }
    
    increaseProductQty = (index) => {
        const product = this.products[index]
        if(product.qty >= product.stock)
            return 'No hay stock suficiente'
        product.qty++;
        product.subTotal += product.price
        this.totalQty++;
        this.totalAmount += product.price
        return null
    }

    decreaseProductQty = (index) =>  {
        const product = this.products[index]
        if(product.qty > 1){
            product.qty--;
            product.subTotal -= product.price
            this.totalQty--;
            this.totalAmount -= product.price
        }
    }

    deleteProduct = (index) =>  {
        this.totalQty -= this.products[index].qty;
        this.totalAmount -= this.products[index].qty * this.products[index].price; 
        this.products.splice(index, 1)
    }
};
