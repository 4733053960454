<template>
  <div class="submit-order">
    <itc-modal :showModal="showModal.cardCalculator" :disableOverflow="true" @close="selectCard">
      <div slot="header">
        <h4>Calculo de tarjeta</h4>
      </div>
      <div slot="body">
        <h6>Monto a financiar: <strong>${{payment.cardRawAmount}}</strong></h6>
        <div class="row">
          <div class="col-6">
            <div class="btn-group my-2">
              <button type="button" class="btn btn-primary width150 dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {{selectedCard.card.name || 'Tarjeta'}}
              </button>
              <div class="dropdown-menu">
                <a 
                  v-for="(card, i) in cards" :key="i"
                  @click="selectedCard.card = card;selectedCard.fee = selectedCard.card.fees[0]"
                  class="set-pointer hoverable dropdown-item">{{card.name}}</a>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="btn-group my-2">
              <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Cuotas: {{selectedCard.fee.fee}}
              </button>
              <div class="dropdown-menu">
                <a 
                  v-for="(fee, i) in selectedCard.card.fees" :key="i" class="dropdown-item" href="#"
                  @click="selectedCard.fee = fee">
                  {{fee.fee}} x ${{(payment.cardFinancedAmount(fee.cte) / fee.fee).toFixed(2)}}
                </a>
              </div>
            </div>
          </div>
        </div>
        <h6 class="mt-2">Monto financiado: <strong>${{payment.cardFinancedAmount(selectedCard.fee.cte)}}</strong></h6>
      </div>
    </itc-modal>
    <itc-modal :showModal="showModal.user"  @close="showModal.user = false">
      <div slot="header">
        <h4>Buscar cliente</h4>
      </div>
      <div slot="body">
        <label for="searchUser"> Buscar
          <input ref="inputName" @input="searchUser" type="text" id="searchUser" class="form-control w-100" placeholder="Apellido - Nombre">
        </label>
        <div class="list-group w-50" style="margin: 0 auto">
          <transition-group name="fade" mode="out-in">
            <a 
              @click.prevent="selectOrderUser(i)"
              v-for="(user, i) in users" :key="user._id" 
              class="list-group-item list-group-item-action set-pointer"> {{user.surname}} {{user.name}}
            </a>
          </transition-group>
        </div>
      </div>
    </itc-modal>
    <itc-card color="#343a40" class="custom-shadow">
      <div slot="header" class="row text-white">
        <div class="col-3">
          <button 
            @click="$emit('click-on-back-button')"
            class="btn btn-info"> Volver
          </button>
        </div>
        <div class="text-center col">
          <h4>Generar remito</h4>
        </div>
        <div class="col-3">
          <button
            :disabled="notReadyToSubmit || disableSubmitButton"
            @click="submitOrder"
            class="btn btn-success float-right"> Finalizar
          </button>
        </div>
      </div>
      <div slot="body">
        <div class="row">
          <div class="col-4">
            <h4>Métodos de pago</h4>
            <hr>
            <div class="row">
              <div class="col-8">
                <h6>Método 1</h6>
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <label class="input-group-text" for="payment1">
                      <input type="checkbox" aria-label="Checkbox for following text input" :checked="true" disabled>
                    </label>
                    <div class="btn-group">
                      <button 
                        type="button" class="btn w-100 btn-danger dropdown-toggle" 
                        data-toggle="dropdown" aria-haspopup="true" 
                        aria-expanded="false">
                        {{payment.paymentMethods[0].type | filterPaymentMethod}}
                      </button>
                      <div class="dropdown-menu">
                        <a 
                          v-for="paymentMethod in payment.availablePaymentMethods" 
                          @click="payment.changePaymentMethod(0, paymentMethod)"
                          class="set-pointer dropdown-item">
                          {{paymentMethod | filterPaymentMethod}}
                        </a>
                      </div>
                    </div>
                    <input 
                      type="number" 
                      :value="payment.paymentMethods[0].amount" 
                      @change="payment.changePaymentMethodAmount(0, $event.target.value)"
                      class="form-control">
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-8">
                <h6>Método 2</h6>
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <label class="input-group-text" for="payment1">
                      <input 
                        @change="payment.toggleSecondMethod()" 
                        :value="payment.secondMethodActive" 
                        :checked="payment.secondMethodActive" 
                        type="checkbox">
                    </label>
                    <div class="btn-group">
                      <button 
                        v-if="payment.secondMethodActive"
                        type="button" class="btn w-100 btn-danger dropdown-toggle" 
                        data-toggle="dropdown" aria-haspopup="true" 
                        aria-expanded="false">
                        {{payment.paymentMethods[1].type | filterPaymentMethod}}
                      </button>
                      <div class="dropdown-menu">
                        <a 
                          v-for="paymentMethod in payment.availablePaymentMethods" 
                          @click="payment.changePaymentMethod(1, paymentMethod)"
                          class="set-pointer dropdown-item">
                          {{paymentMethod | filterPaymentMethod}}
                        </a>
                      </div>
                    </div>
                  </div>
                  <input 
                    v-if="payment.secondMethodActive" 
                    :value="payment.paymentMethods[1].amount" 
                    @change="payment.changePaymentMethodAmount(1, $event.target.value)"
                    type="number" class="form-control">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-8">
                <h6>Cálculo de tarjeta</h6>
                <button 
                  :disabled="!payment.hasCardPaymentMethod" 
                  @click="showModal.cardCalculator = true" class="btn btn-sm btn-info mt-1">Calcular
                </button>
                <strong v-if="payment.hasCardPaymentMethod" class="mx-3">
                  ${{payment.cardFinancedAmount(selectedCard.fee.cte)}} en {{selectedCard.fee.fee}} cuota/s
                </strong> 
              </div>
            </div>
          </div>
          <div class="col-4">
            <h4>Datos del cliente<i @click="user={email: ''}" class="far mx-2 text-danger set-pointer pt-1 fa-times-circle"></i></h4>
            <hr>
            <div class="form-group row">
              <label for="inputEmail3" class="col-sm-2 col-form-label">Nombre</label>
              <div class="col-sm-10">
                <input 
                  :value="`${user.surname || ''} ${user.name || ''}`"
                  :readonly="inputDisabled" 
                  @click="openUsersModal" 
                  @focusout="inputDisabled = true"
                  type="text" class="form-control" id="inputEmail3">
                </div>
              </div>
              <div class="form-group row">
                <label for="inputPassword3" class="col-sm-2 col-form-label">Email</label>
                <div class="col-sm-10">
                  <input v-model="user.email" type="email" class="form-control" id="inputPassword3">   
                </div>
              </div>
            </div>
            <div class="col-4">
              <h4>Resumen</h4>
              <hr>
              <h6>Monto final efectivo: ${{payment.totalAmount}}</h6>
              <h6 v-if="!notReadyToSubmit && payment.hasCardPaymentMethod">
                Monto final financiado: ${{payment.financedTotalAmount(selectedCard.fee.cte)}} | 
                <small>{{selectedCard.card.name}} x {{selectedCard.fee.fee}}</small>
              </h6>
              <h6>Cantidad de productos: {{totalQty}}</h6>
              <div class="form-check">
                <input ref="printOrder" v-model="printOrder" type="checkbox" class="form-check-input">
                <label class="form-check-label">Imprimir orden</label>
              </div>
              <div class="form-group">
                <textarea class="form-control" v-model="observ" rows="3" placeholder="Observaciones" style="resize:none"></textarea>
              </div>
            </div>
          </div>
        </div>
      </itc-card>
    </div>
  </template>

<script>
import axios from 'axios'

import * as utils from '@/assets/utils/utils.js'
import { Payment } from '@/assets/utils/models/Payment.js'

import Card from '@/components/mix/Card'
import Modal from '@/components/mix/Modal'

export default {
  data(){
    return {
      payment: new Payment(this.totalAmount),
      selectedCard: {
        card: {},
        fee: {
          fee: 1,
          cte: 1
        }
      },
      users: [],
      user: {email: ''},
      inputDisabled: true,
      showModal: {user: false, cardCalculator: false},      
      cards: [],
      observ: '',
      printOrder: false,
      disableSubmitButton: false
    }
  },
  props: {
    totalAmount: Number,
    totalQty: Number
  },
  computed: {
    notReadyToSubmit(){
      return this.payment?.hasCardPaymentMethod && !this.payment?.hasSelectedCard
    }
  },
  methods: {
    submitOrder(){
      if(this.disableSubmitButton || this.notReadyToSubmit) return
      this.disableSubmitButton = true
      const payment = this.payment.formatPaymentForSubmit(this.selectedCard.fee.cte)
      /* const payment = [] */
      /* for(let key in this.paymentMethod){ */
        /* if(this.paymentMethod[key].active){ */
          /* payment.push({ */
            /* methodName: key, */
            /* amount: key == 'card' ? this.financedAmount : this.paymentMethod[key].amount */
          /* }) */
        /* } */
      /* } */
      this.$emit('order-submit', {
        user :this.user, 
        payment, 
        totalAmount: this.payment.financedTotalAmount(this.selectedCard.fee.cte), 
        observ: this.observ, 
        printOrder: this.printOrder
      })
      this.user = {email: ''};
      this.observ = ''
      this.printOrder = false
      setTimeout(() => {
        this.disableSubmitButton = false
      }, 2000)
    },
    openUsersModal(){
      this.inputDisabled = false;
      this.showModal.user = true
      this.$nextTick(() => {
        this.$refs.inputName.focus()
      })
    },
    selectOrderUser(index){
      this.showModal.user = false;
      this.user = this.users[index]
    },  
    selectCard(){
      const name = this.selectedCard.card.name
      const fee = this.selectedCard.fee.fee
      if(name)
        this.payment.changeSelectedCard(name, fee)
      this.showModal.cardCalculator = false
    },
    async searchUser(event){
      const searcher = event.target.value
      if(searcher.length > 2){
        const surname = searcher.split(" ")[0]
        const name = searcher.split(" ")[1]
        const resp = await axios.get(`/user/name?name=${name}&surname=${surname}`)
        this.users = resp.data
      }
      else
        this.users = []
    },
    clickPrintOrder(){
      this.$refs.printOrder.click()
    }
  },
  filters:{
    filterPaymentMethod(name){
      return utils.filterPaymentMethodName(name)
    }
  },
  activated(){
    this.payment = new Payment(this.totalAmount)
    this.printOrder = false
    this.selectedCard = {card: {},fee: {fee: 1, cte: 1}}
  },
  async created(){
    const resp = await axios.get('/card')
    this.cards = resp.data
  },
  components: {
    'itc-card': Card,
    'itc-modal': Modal,
  }
}
</script>

<style lang="scss" scoped>
.width150{
  width: 150px;
  display: inline-block;
}
.user-item{
  font-size: 14px;
  cursor:pointer;
  border-bottom: 1px solid transparentize($color: #d3d3d3, $amount: 0.2); 
}
.user-item:hover{
  background-color: transparentize($color: #d3d3d3, $amount: 0.8); ;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to{
  opacity: 0;
}
</style>
