<template>
  <div class="admin-sidebar text-white text-center">
    <itc-sidebar>
        <div class="sidebar-item w-100 p-3">
            <label for="serialSearch">Código de barras</label>
            <input
                ref="codeBarInput"
                @keyup.enter="submitSearch(serialSearcher ,'serial');serialSearcher = ''"
                :disabled="disableInput"
                v-model="serialSearcher" 
                id="serialSearch" type="text" class="form-control">
        </div> 
        <div class="sidebar-item w-100 p-3">
            <label for="nameSearch">Nombre</label>
            <input 
                @keyup.enter="submitSearch(nameSearcher, 'name');nameSearcher = ''"
                :disabled="disableInput" 
                v-model="nameSearcher" 
                id="nameSearch" type="text" class="form-control">
        </div>
        <div class="sidebar-item w-100 p-3">
            <label for="nameSearch">Servicio técnico</label>
            <input 
                @keyup.enter="submitSearch(tsSearcher.toUpperCase(), 'serviceNumber');tsSearcher = ''"
                :disabled="false"
                placeholder="Proximamente..." 
                v-model="tsSearcher" 
                id="tsSearch" type="text" class="form-control">
        </div>
        <div class="sidebar-item w-100 set-pointer p-3">
            <button
                :disabled="disableInput"
                @click="$emit('generate-return')"
                class="btn btn-info">Generar cambio
            </button>
        </div>
        <div class="sidebar-item w-100 p-3">
            <transition name="fade" mode="out-in">
                <button
                    :disabled="disableInput"
                    v-if="!generateExtraction" 
                    @click="generateExtraction = true"
                    class="btn btn-primary">Generar extracción</button>
                <div v-else>
                    <div class="row">
                        <div class="col-6">
                            <label>Observación</label>
                            <input v-model="extraction.observ" type="text" class="form-control">
                        </div>
                        <div class="col-6">
                            <label>Monto</label>  
                            <input v-model="extraction.amount" type="number" class="form-control">
                        </div>
                    </div>
                    <div class="row my-3">
                        <div class="col">
                            <button @click="generateExtraction = false" class="btn btn-warning float-left">Cancelar</button>
                            <button @click="submitGenerateExtraction" class="btn btn-success float-right">Ok</button>
                        </div>
                    </div>
                </div>
            </transition>
        </div> 
        <router-link tag="button" class="btn btn-warning m-2 back-button" to="/localshop/menu">Volver</router-link>
    </itc-sidebar>
  </div>
</template>

<script>
import axios from 'axios'

import SideBar from '@/components/mix/SideBar'

export default {
    data(){
        return {
            generateExtraction: false,
            nameSearcher: '',
            tsSearcher: '',
            serialSearcher: '',
            extraction: {
                observ: '',
                amount: 0
            }
        }
    },
    methods: {
        async submitGenerateExtraction(){
            const resp = await axios.post('/cash-register/extraction', {
                id: this.cashRegisterId,
                observ: this.extraction.observ,
                amount: this.extraction.amount
            })
            this.$emit('extraction-generated', resp.data)
            this.generateExtraction = false
            this.extraction = {observ: '', amount: 0}
            this.$toasted.success('Extracción generada')
        },
        focusOnCodeBar(){
            this.$nextTick(() => {
                this.$refs.codeBarInput.focus()
            })
            this.serialSearcher = ''
            this.nameSearcher = ''
        },
        clearInputs(){
            this.serialSearcher = ''
            this.nameSearcher = ''
        }
    },
    created(){
        this.focusOnCodeBar()
    },
    props: {
        submitSearch: Function,
        disableInput: Boolean,
        cashRegisterId: String,
    },
    components: {
        'itc-sidebar': SideBar
    }
}
</script>

<style lang="scss" scoped>
.back-button{
    position: fixed;
    left: 0;
    bottom: 0
}
.sidebar-item{
  display: inline-block;
  color: #fff;
  font-size: 15px;
  padding-top: 13px;
  border-bottom: 1px solid transparentize($color: #D3D3D3, $amount: 0.9)
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to{
  opacity: 0;
}
i.sidebar-icon {
  margin: 0px 20px 0px 20px
}
</style>