export class PaymentMethod {
  constructor (type, amount) {
    this.type = type
    this.amount = amount
    if(this.type == 'card') 
      this.cardInfo = {
        selectedFee: '',
        cardName: '',
      }
  }

  changeCardInfo(cardName, fee){
    if(this.type != 'card') return
    this.cardInfo.selectedFee = fee
    this.cardInfo.cardName = cardName
  }

  financedAmount(cte){
    if(this.type != 'card') return 0
    return (this.amount * cte).toFixed(2)
  }

  changeAmount(newAmount){
    this.amount = newAmount
  }

  get hasSelectedCard(){
    if(this.type != 'card') return
    return this.cardInfo?.cardName.length > 0
  }
}


