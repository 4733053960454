<template>
    <div class="bill-generator">
        <itc-billgenerator-sidebar 
            ref="sideBar"
            :submitSearch="submitSearch"
            :disableInput="disableInput"
            :cashRegisterId="cashRegister._id"
            @generate-return="openGenerateReturnModal"
            @extraction-generated="cashRegister = $event">
        </itc-billgenerator-sidebar>
        <itc-modal :showModal="showImg" @close="showImg = false">
            <div slot="header">
                {{header}}
            </div>
            <div slot="body" class="w-100 text-center">
                <img :src="imgPath" id="modalImg" class="showModal-content">
            </div>
        </itc-modal> 
        <itc-modal :showModal="showModal.technicalService">
            <div slot="header">
                <h4>{{tmpTechnicalService.name}}</h4>
            </div>
            <div slot="body">
                <div class="row">
                    <div class="col-6">
                        <label>
                            Presupuesto:
                            <br>
                            <strong>${{tmpTechnicalService.budget}}</strong>
                        </label>
                    </div>
                    <div class="col-6">
                        <label>
                            Seña actual
                            <br>
                            <strong>${{tmpTechnicalService.downPayment || 0}}</strong>
                        </label>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label>
                            Monto de la seña
                            <input ref="downPayment" v-model="tmpTechnicalService.price" type="number" step="0.001" class="form-control">
                        </label>
                    </div>
                </div>
                <span class="text-danger">{{technicalServiceError}}</span>
            </div>
            <div slot="footer">
                <div class="row">
                    <div class="col">
                        <button 
                            :disabled="technicalServiceError != ''"
                            @click="addTechnicalServiceDownPayment"
                            class="modal-default-button btn btn-success float-right mx-2">OK</button>
                        <button 
                            @click="showModal.technicalService = false"
                            class="modal-default-button btn btn-warning float-right mx-2">Cerrar</button>
                    </div>
                </div>
            </div>
        </itc-modal> 
        <itc-modal :showModal="showModal.searchName" @close="showModal.searchName = false">
            <div slot="header">
                <h4>Búsqueda por nombre</h4>
            </div>
            <div slot="body">
                <ul class="list-group list-group-flush">
                    <li 
                        v-for="(product, i) in searchedProducts" :key="i"
                        @click="addProductByName(product)"
                        class="list-group-item">
                        {{ product.name }}
                    </li>
                    <li v-if="searchedProducts.length == 0">No se encontraron productos</li>
                </ul>
            </div>
        </itc-modal>
        <itc-modal :showModal="showModal.generateChange" @close="showModal.generateChange = false">
            <div slot="header">
                <h4>Generar cambio</h4>
            </div>
            <div slot="body">
                <label>
                    Código de barras
                    <input ref="generateReturnInput" v-model="changeSerial" type="text" class="form-control">
                </label>
                <br>
                <span class="text-danger">{{changeError}}</span>
            </div>
            <div slot="footer">
                <div class="row">
                    <div class="col">
                        <button class="modal-default-button btn btn-success float-right mx-2" @click="generateChange">OK</button>
                        <button class="modal-default-button btn btn-warning float-right mx-2" @click="showModal.generateChange = false">Cerrar</button>
                    </div>
                </div>
            </div>
        </itc-modal>
        <div class="main" v-if="cashRegisterStatus == 200" key="200">
            <h4 class="pt-3">Terminal punto de venta</h4>
            <hr>
            <div class="row">
                <div class="col-3">
                    <itc-card color="#343a40" class="custom-shadow my-2 h-100">
                        <div slot="header" class="text-white text-center">
                            <h4>Caja diaria 
                                <button 
                                    @click="disableInput = true;cashRegisterStatus = 502;showBackButton = true" 
                                    class="btn btn-sm btn-warning">Cerrar
                                </button>
                                <i 
                                    @click="showAmounts = !showAmounts"
                                    :class="{'fa-plus': !showAmounts, 'fa-minus': showAmounts}"
                                    class="fas text-muted ml-4 set-pointer"></i>
                            </h4>
                        </div>
                        <div slot="body">
                            <h6>Estado: {{cashRegister.isClosed ? 'cerrada' : 'activa'}}</h6>
                            <h6>Fecha inicial: {{cashRegister.initialDate}}</h6>
                            <template v-if="showAmounts">
                                <h6>Monto inicial: ${{cashRegister.initialAmount}}</h6>
                                <h6>Monto actual: ${{cashRegister.totalAmount}}</h6>
                            </template>
                        </div>
                    </itc-card>
                </div>
                <div class="col-9">
                    <itc-card color="#343a40" class="custom-shadow my-2 h-100">
                        <div slot="header" class="text-white text-center">
                            <h4>General</h4>
                        </div>
                        <div slot="body">
                            <div class="row">
                                <div class="col-4">
                                    <h6>Operador: {{$store.getters.getUserName}}</h6>                                   
                                </div>
                                <div class="col-4">
                                    <strong>Monto: ${{cart.totalAmount}}</strong>
                                    <br>
                                    <strong>Cantidad de productos: {{cart.totalQty}}</strong>
                                    <br>
                                    <br>
                                    <button 
                                        :disabled="cart.products.length == 0"
                                        @click="actualComponent = 'itc-submit-order';disableInput = true;$refs.sideBar.clearInputs()" 
                                        ref="generateOrderButton"
                                        class="btn btn-info mx-2">Generar remito</button>
                                    <button class="btn btn-success mx-2 disabled">Generar factura</button>
                                </div>
                                <div class="col-4">
                                    <h5>Atajos de teclado</h5>
                                    <hr>
                                    <div class="row">
                                        <div class="col">
                                            <span><strong class="arrow-key">↑</strong>: Generar remito</span>
                                            <br>
                                            <span><strong class="arrow-key">↓</strong>: Imprimir</span>
                                        </div>
                                        <div class="col">
                                            <span><strong class="arrow-key">→</strong>: Finalizar orden</span>
                                            <br>
                                            <span><strong class="arrow-key">←</strong>: Cursor en barras</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </itc-card>
                </div>
            </div>
            <transition name="fade" mode="out-in">
                <keep-alive>
                    <component
                        class="mt-4"
                        v-if="cart.products.length > 0"
                        :is="actualComponent"
                        :products="cart.products" 
                        :totalAmount="cart.totalAmount"
                        :totalQty="cart.totalQty"
                        @click-on-back-button="actualComponent = 'itc-localshop-table';disableInput = false"
                        @order-submit="submitOrder"
                        @decrease="cart.decreaseProductQty($event)"
                        @increase="increaseProduct"
                        @delete="cart.deleteProduct($event)"
                        @show-image="showImage"
                        ref="childComponent">
                    </component>
                </keep-alive>
            </transition>
        </div>
        <div v-else-if="cashRegisterStatus == 501" class="main"  key="501">
            <itc-card color="#343a40" class="custom-shadow my-2">
                <div slot="header" class="text-white text-center">
                    <h4>Apertura de caja diaria</h4>
                </div>
                <div slot="body">
                    <h5 class="text-center"><strong>Debes abrir una nueva caja diaria para continuar</strong></h5>
                    <div class="w-25 align-div text-center">
                        <hr>
                        <div class="row">
                            <div class="col-8">
                                <label for="nameSearch">Monto inicial</label>
                                <input 
                                    v-model="initialAmount"
                                    type="text" class="form-control">
                            </div>
                            <div class="col-4">
                                <button :disabled="disableCreateButton" @click="submitNewCashRegister" class="btn btn-success custom-margin">Continuar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </itc-card>
        </div>
        <div v-else class="main"  key="502">
            <itc-card color="#343a40" class="custom-shadow my-2">
                <div slot="header" class="text-white text-center">
                    <div class="row">
                        <div class="col-3">
                            <button 
                                v-if="showBackButton"
                                @click="showBackButton = false;cashRegisterStatus = 200;disableInput = false"
                                class="float-left btn btn-info"> Volver
                            </button>
                        </div>
                        <div class="text-center col">
                            <h4>Cierre de caja diaria</h4>
                        </div>
                        <div class="col-3"></div>
                    </div>
                </div>
                <div slot="body">
                    <h5 class="text-center"><strong>Debes cerrar la caja diaria para continuar</strong></h5>
                    <div class="w-50 align-div text-center">
                        <h6>Fecha de inicio: {{cashRegister.initialDate}}</h6>
                        <hr>
                        <div class="table-responsive">
                            <table class="w-100">
                                <thead>
                                    <tr>
                                        <th>Método</th>
                                        <th>Monto</th>
                                        <th>Cantidad</th>
                                        <th>Confirmar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><small>Inicial</small></td>
                                        <td><small>${{cashRegister.initialAmount}}</small></td>
                                        <td><small>-</small></td>
                                        <td><small>-</small></td>
                                    </tr>
                                    <tr v-for="(amount, key) in cashRegister.amounts" :key="key">
                                        <td><small>{{key | filterPaymentMethodName}}</small></td>
                                        <td><small>${{amount.amount}}</small></td>
                                        <td><small>{{amount.count}}</small></td>
                                        <td><div class="checkbox"><input v-model="amount.matches" type="checkbox" value=""></div></td>
                                    </tr>
                                    <tr>
                                        <td><small>Gastos</small></td>
                                        <td><small>${{cashRegister.expenses.totalExpenses}}</small></td>
                                        <td><small>{{cashRegister.expenses.expenses.length}}</small></td>
                                        <td><small>-</small></td>
                                    </tr>
                                    <tr class="text-white" style="background-color:#343a40">
                                        <td></td>
                                        <td><strong>Total: ${{cashRegister.totalAmount}}</strong></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="form-group m-2">
                            <label for="comment">Observaciones</label>
                            <textarea v-model="cashRegister.observs" class="form-control" rows="2" style="resize:none"></textarea>
                        </div>
                        <button @click="submitCloseCashRegister" class="btn btn-primary m-2">Confirmar y continuar</button>
                    </div>
                </div>
            </itc-card>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

import BillGeneratorSideBar from '@/components/shared/localshop/BillGeneratorSideBar'
import ProductsTable from '@/components/localshop/ProductsTable'
import SubmitOrder from '@/components/localshop/SubmitOrder'
import Card from '@/components/mix/Card'
import Modal from '@/components/mix/Modal'

import * as utils from '@/assets/utils/utils.js'

import { Cart } from '@/assets/utils/models/cart_model.js'

import * as pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
    data(){
        return {
            showBackButton: false,
            cashRegisterStatus: 200,
            cashRegister: {},
            showModal: {searchName: false, generateChange: false, technicalService: false},
            disableInput: false,
            showAmounts: false,
            actualComponent: 'itc-localshop-table',
            cart: null,
            searchedProducts: [],
            initialAmount: 0,
            changeSerial: '',
            changeError: '',
            disableCreateButton: false,
            showImg: false,
            imgPath: '',
            header: '',
            tmpTechnicalService: {}
        }
    },
    filters: {
        filterPaymentMethodName(name){
            return utils.filterPaymentMethodName(name)
        }
    },
    computed: {
        technicalServiceError(){
            if((this.tmpTechnicalService.budget - (this.tmpTechnicalService.downPayment || 0)) < +this.tmpTechnicalService.price)
                return 'Seña invalida'
            else return ''
        }
    },
    methods: {
        openGenerateReturnModal(){
            this.showModal.generateChange = true;
            this.$nextTick(() => {
                this.$refs.generateReturnInput.focus()
            })
        },
        showImage(event){
            this.header = event.name;
            this.imgPath = `${process.env.VUE_APP_PUBLIC_PATH}${event.path}`
            this.showImg = true
        },
        async submitOrder(event){
            this.cart.setTotalAmount(event.totalAmount)
            const resp = await axios.post('/order/submit', {
                    user: event.user.email != '' ? JSON.stringify(event.user) : null, 
                    payment: JSON.stringify(event.payment),
                    cart: JSON.stringify(this.cart),
                    cashRegisterId: this.cashRegister._id,
                    onlineBuy: false,
                    observ: event.observ,
                    printOrder: event.printOrder
                }
            )
            this.actualComponent = 'itc-localshop-table'
            if(event.printOrder){
                const newWindow = window.open({});
                newWindow.document.write(resp.data.printInfo)
                setTimeout(() => {
                    newWindow.print()
                    newWindow.close()
                }, 2000)
            }
            this.cashRegister = resp.data.cashRegister
            this.cart = new Cart()
            this.disableInput = false
            this.$refs.sideBar.focusOnCodeBar()
            this.$toasted.success('Venta exitosa')
        },
        addProductToCart(product){
            if(!product)
                this.$toasted.error('Producto no disponible')
            else{
                const errorMsg = this.cart.addProduct(product, 'product')
                if(!errorMsg)
                    this.$toasted.success('Producto agregado')
                else
                    this.$toasted.info(errorMsg)
            }
        },
        addTechnicalServiceDownPayment(){
            this.tmpTechnicalService.price = +this.tmpTechnicalService.price
            this.cart.addProduct(this.tmpTechnicalService, 'ts-downpayment')
            this.showModal.technicalService = false
        },
        addTechnicalServiceToCart(technicalService){
            this.cart.addProduct(technicalService, 'ts')
            this.$toasted.success('Servicio técnico agregado')
        },
        async submitSearch(searcher, type){
            let resp;
            if(type == 'serviceNumber') resp = await axios.get(`/technical-service/serviceNumber?searcher=${searcher}&mode=tpv`)
            else resp = await axios.get(`/product/${type}?searcher=${searcher}&mode=tpv`)
            if(type == 'serial')
                this.addProductToCart(resp.data[0])
            else if(type == 'serviceNumber'){
                const technicalService = resp.data[0]
                if(!technicalService) this.$toasted.error('Servicio técnico no disponible')
                if(technicalService.isReadyForRetire)
                    this.addTechnicalServiceToCart(technicalService)
                else {
                    this.tmpTechnicalService = technicalService
                    this.showModal.technicalService = true
                }
            }
            else {
                this.searchedProducts = resp.data
                this.showModal.searchName = true
            }
        },
        /* async submitSearch(searcher, type){
            let resp;
            if(type == 'serviceNumber') resp = await axios.get(`/technical-service/serviceNumber?searcher=${searcher}&mode=tpv`)
            else resp = await axios.get(`/product/${type}?searcher=${searcher}&mode=light`)
            if(type == 'serial' || type == 'serviceNumber'){
                const product = resp.data[0]
                if(!product)
                    this.$toasted.error(type == 'serial' ? 'Producto no disponible' : 'Servicio técnico no disponible')
                else{
                    const errorMsg = this.cart.addProduct(product)
                    if(!errorMsg)
                        this.$toasted.success(type == 'serial' ? 'Producto agregado' : 'Servicio técnico agregado')
                    else
                        this.$toasted.info(errorMsg)
                }
            }
            else {
                this.searchedProducts = resp.data
                this.showModal.searchName = true
            }
        }, */
        increaseProduct(event){
            const errorMsg = this.cart.increaseProductQty(event)
            if(errorMsg)
                this.$toasted.error(errorMsg)
        },
        addProductByName(product) {
            this.showModal.searchName = false;
            const errorMsg = this.cart.addProduct(product, 'product')
                if(!errorMsg)
                    this.$toasted.success('Producto agregado')
                else
                    this.$toasted.info(errorMsg)
            this.searchedProducts = []
        },
        async submitNewCashRegister(){
            if(this.initialAmount <= 0) return;
            this.disableCreateButton = true
            const resp = await axios.post('/cash-register/open', {initialAmount: this.initialAmount})
            this.cashRegister = resp.data
            this.cashRegisterStatus = 200;
            this.disableInput = false
            this.$toasted.success('Caja abierta')
            this.disableCreateButton = false
        },
        async submitCloseCashRegister(){
            this.cashRegister.hasErrors = false
            for(let key in this.cashRegister.amounts)
                if(!this.cashRegister.amounts[key].matches) this.cashRegister.hasErrors = true
            await axios.post('/cash-register/close', {
                observs: this.cashRegister.observs, 
                amounts: JSON.stringify(this.cashRegister.amounts),
                id: this.cashRegister._id,
                hasErrors: this.cashRegister.hasErrors
            })
            this.cashRegisterStatus = 501;
            this.$toasted.success('Caja Cerrada')
        },
        async generateChange(){
            const resp = await axios.get(`/product/check-availability?serial=${this.changeSerial}`)
            if(resp.data.err) return this.changeError = resp.data.msg
            await axios.post('/product/generate-return', {serial: this.changeSerial})
            this.changeSerial = ''
            this.showModal.generateChange = false
            this.$toasted.success('Devolución generada')
        },
        handleKeyPress(e){
            const keyPressed = e.keyCode
            switch(keyPressed){
                case 37: // ←
                    if(this.actualComponent == 'itc-localshop-table') this.$refs.sideBar.focusOnCodeBar()
                    break;
                case 38: // ↑
                    this.$refs.generateOrderButton.click()
                    break;
                case 39: // →
                    if(this.actualComponent == 'itc-submit-order') this.$refs.childComponent.submitOrder();this.$refs.sideBar.focusOnCodeBar()
                    break;
                case 40: // ↓
                    if(this.actualComponent == 'itc-submit-order') this.$refs.childComponent.clickPrintOrder()
                    break;
            }
        }
    },
    components: {
        'itc-billgenerator-sidebar': BillGeneratorSideBar,
        'itc-localshop-table': ProductsTable,
        'itc-submit-order': SubmitOrder,
        'itc-card': Card,
        'itc-modal': Modal,
    },
    async created(){
        window.addEventListener('keyup', this.handleKeyPress)
        this.cart = new Cart();
        const resp = await axios.get('/cash-register/current')
        const status = resp.data.status
        switch(status){
            case 200:
                this.cashRegister = resp.data.cashRegister
                break;
            case 501:
                this.cashRegisterStatus = 501
                this.disableInput = true
                break;
            case 502:
                this.cashRegisterStatus = 502
                this.disableInput = true
                this.cashRegister = resp.data.cashRegister
                break;
        }
    },
    beforeDestroy(){
        window.removeEventListener('keyup', this.handleKeyPress);
    }
}
</script>

<style lang="scss" scoped>
.main {
  position: relative;
  width: 82%;
  top: 0;
  left: 16%;
}

.arrow-key {
    font-size: 1.1em;
    font-weight: 800;
}

.list-group-item{
    cursor: pointer;
}
.list-group-item:hover{
    background-color: lightgreen;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to{
  opacity: 0;
}
</style>
