<template>
    <div class="products-list custom-shadow">
        <table id="tablePreview" class="table table-striped">
            <thead class="thead-dark">
                <tr>
                    <th>Producto</th>
                    <th>Cantidad</th>
                    <th>Precio</th>
                    <th>Sub-total</th>
                    <th>Eliminar</th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(product, i) in products" 
                    :key="i" 
                    class="product-item">
                    <th 
                        @click="$emit('show-image', {name: product.name, path: product.imgPath.primary})" 
                        scope="row" class="set-pointer">{{product.name}}
                    </th>
                    <td class="w-25">
                        <div class="input-group w-50">
                            <div class="input-group-append" id="button-addon4">
                                <button @click="$emit('decrease', i)" class="btn btn-danger" type="button"><i class="fas fa-minus"></i></button>
                                <input type="text" class="form-control" v-model="product.qty" readonly>
                                <button @click="$emit('increase', i)" class="btn btn-success" type="button"><i class="fas fa-plus"></i></button>
                            </div>
                        </div>
                    </td>
                    <td>${{product.price}}</td>
                    <td>${{product.subTotal}}</td>
                    <td><button 
                        @click="$emit('delete', i)"
                        class="btn btn-danger">
                        <i class="far fa-trash-alt"></i></button>
                    </td>
                </tr>
                <tr class="total-amount text-white" style="background-color:#343a40">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Total: ${{totalAmount}}</td>
                    <td></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    data(){
        return {
            publicPath: process.env.VUE_APP_PUBLIC_PATH
        }
    },
    props: {
        products: Array,
        totalAmount: Number
    }
}
</script>

<style lang="scss" scoped>
.total-amount{
    background-color: #343a40;
}
.product-item:hover{
    background-color: lightgreen;
}
</style>